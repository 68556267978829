import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  form {
    width: 100%;
    max-width: 350px;

    .logo {
      display: block;
      width: 100px;
      height: 100px;
      margin: 0 auto;
      margin-bottom: 20px;
    }
  }
`;

export const ButtonSubmit = styled.button`
  width: 100%;
  background-color: ${({ theme: { colors } }) => colors.primary}!important;
  -webkit-box-align: baseline;
  align-items: baseline;
  border-width: 0px;
  border-radius: 3px;
  box-sizing: border-box;
  display: inline-flex;
  font-size: inherit;
  font-style: normal;
  font-family: inherit;
  font-weight: 500;
  max-width: 100%;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: background 0.1s ease-out,
    box-shadow 0.15s cubic-bezier(0.47, 0.03, 0.49, 1.38);
  white-space: nowrap;
  background: rgba(9, 30, 66, 0.04);
  cursor: pointer;
  height: 2.28571em;
  line-height: 2.28571em;
  padding: 0px 10px;
  vertical-align: middle;
  -webkit-box-pack: center;
  justify-content: center;
  color: #fff;

  &:disabled {
    opacity: 70%;
    cursor: not-allowed;
  }
`;
